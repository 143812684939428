/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W styczniu 2024 roku rozpoczęły się prace związane z przebudową drogi gminnej nr 150550C w miejscowości Łojewo (województwo kujawsko-pomorskie). Droga łączy gminne miejscowości Łojewo oraz Ostrowo Krzyckie."), "\n", React.createElement(_components.p, null, "W ramach inwestycji zostaną wykonane między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbiórka elementów dróg, ogrodzeń i przepustów,"), "\n", React.createElement(_components.li, null, "regulacja i zabezpieczenie urządzeń infrastruktury podziemnej,"), "\n", React.createElement(_components.li, null, "wykonanie wykopów w gruntach oraz nasypów,"), "\n", React.createElement(_components.li, null, "profilowanie i zagęszczenie podłoża,"), "\n", React.createElement(_components.li, null, "oczyszczenie i skropienie warstw konstrukcyjnych,"), "\n", React.createElement(_components.li, null, "wykonanie podbudowy z kruszywa łamanego stabilizowanego mechanicznie,"), "\n", React.createElement(_components.li, null, "wykonanie ulepszonego podłoża z gruntu stabilizowanego cementem,"), "\n", React.createElement(_components.li, null, "wykonanie nawierzchni z betonu oraz destruktu asfaltowego,"), "\n", React.createElement(_components.li, null, "oznakowanie drogi poziome i pionowe,"), "\n", React.createElement(_components.li, null, "wstawienie barier ochronnych stalowych,"), "\n", React.createElement(_components.li, null, "nasadzenie zieleni drogowej oraz humusowanie z obsianiem trawą."), "\n"), "\n", React.createElement(_components.p, null, "Droga zostanie oddana do użytku do czerwca 2024 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
